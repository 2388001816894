<template>
  <transition name="menu">
    <form-status :is-edit="false"/>
  </transition>
</template>

<script>
import formStatus from './components/formStatus'

export default {
  name: 'CreateformStatus',
  components: { formStatus }
}
</script>

